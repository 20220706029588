/**
 * Set spacing beneath content based on footer height
 *
 * @author <john@chatmandesign.com>
 * @author <corey@chatmandesign.com>
 */
// (function($) {
// 	let $footer = $('#theme-footer');
// 	let $content = $('#content');

// 	function updateContentFooterPadding() {
// 		if ( $footer.length ) {
// 			let footerHeight = Math.ceil( $footer.outerHeight() );
// 			$content.css('padding-bottom', `${footerHeight}px`);
// 		}
// 	}

// 	$(window).on('load resize', function() {
// 		updateContentFooterPadding();
// 	});
// })(jQuery);
